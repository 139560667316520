import styled from "styled-components";

const ButtonClose = styled.button`
    position: absolute;
    background-color: transparent;
    border: 0;
    z-index: 10;
    right: 3rem;
    top: 2.5rem;
    width: 20px;
    height: 20px;

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
`

export default ButtonClose